<script setup>
import FormSingleSettingBar from '@/Components/Form/FormSingleSettingBar.vue';
import { Link } from '@inertiajs/vue3';
import { inject } from 'vue';

const route = inject('route');

const props = defineProps({
    company: Object,
    locations: Array,
});
</script>

<template>
    <FormSingleSettingBar :title="$t('Locations')" removeInnerPadding>
        <div
            class="grid grid-cols-3 border-b text-blue border-blue/30 min-h-13"
            v-for="location in locations"
            :key="location.id"
        >
            <div class="self-center font-bold">{{ location.nickname }}</div>
            <div class="self-center">
                {{
                    location.address.street_name +
                    ' ' +
                    location.address.street_number +
                    (location.address.street_number_addition ?? '') +
                    ', ' +
                    location.address.city
                }}
            </div>

            <Link :href="route('staff.locations.edit', location.id)" class="self-center justify-self-end">
                <img class="float-right w-2 h-auto" src="@/../img/icon/pagination-arrow.svg" :alt="$t('Link')" />
            </Link>
        </div>
        <div class="flex items-center justify-end min-h-10">
            <Link
                :href="route('staff.companies.locations.create', { company: company.id })"
                class="self-center font-semibold justify-self-end text-blue-light"
            >
                {{ $t('Add {model}', { model: $t('location') }) }}
            </Link>
        </div>
    </FormSingleSettingBar>
</template>
